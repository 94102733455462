<template>
  <div class="home">
<!--    <div class="marquee"><span>First time purchase get extra 5% off, use code: WELCOME</span></div>-->
    <div class="banner">
      <div class="banner-inner">
        <h1>Empowering <span class="pc-display"></span>Connectivity</h1>
        <p>Higher Efficiency . <span class="pc-display"></span>Lower Cost . <span class="pc-display"></span>More Convenient </p>
        <div class="button1" @click="$router.push('/product')">Get Your eSIM Now</div>
      </div>
      <div class="banner-video">
        <video class="item-video"
               muted autoplay preload loop
               x5-video-player-fullscreen="true"
               x5-playsinline
               playsinline
               webkit-playsinline
               src="https://1317409195.vod-qcloud.com/3f902551vodtranshk1317409195/00f8b01c3270835009418288300/v.f100840.mp4">
        </video>
      </div>
    </div>
    <div class="home-img" v-if="imgList && imgList.length">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in imgList" :key="index">
          <a :href="item.url"><img :src="item.image" :alt="item.name"></a>
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
    </div>

    <!-- pc端 -->
    <div class="home-intro">
      <div class="home-intro-div" v-for="(item, index) in introList" :key="index">
        <img :class="item.type == 1 ? 'imgs' : ''" :src="item.imgUrl" :alt="item.title">
        <div>
          <p>{{item.title}}</p>
          <span>{{item.span}}</span>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="home-intro-swiper">
      <swiper :options="swiperOption2">
        <swiper-slide v-for="(item, index) in introList" :key="index">
          <img :class="item.type == 1 ? 'imgs' : ''" :src="item.imgUrl" :alt="item.title">
          <div>
            <p>{{item.title}}</p>
            <span>{{item.span}}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>


    <div class="home-box">
      <div class="home-box-title">
        <h1>HOT SALE</h1>
        <div @click="$router.push('/product')">
          More
          <img src="@/assets/image/icon/icon-arrow.png" alt="">
        </div>
      </div>
      <div class="home-box-list">
        <div class="list-div" v-for="(item, index) in dataList" :key="index" @click="toDetails(item)">
          <div class="list-div-img">
            <img class="img" :src="item.image" alt="">
            <div class="shop" @click.stop="toShop(item)" >
              <img src="@/assets/image/icon/icon-shop.png" alt="">
            </div>
            <div class="love" @click.stop="toLove(item)" v-if="false">
              <img src="@/assets/image/icon/icon-love.png" alt="">
            </div>
          </div>
          <h2 class="list-div-h2">{{item.sketch}}</h2>
          <h3 class="list-div-h3">{{item.name}}</h3>
          <h4 class="list-div-h4">
            <!-- ~ USD <span>{{'$ ' + item.max_price}}</span> -->
            From USD <span>{{'$ ' + item.min_price.toLocaleString()}}</span>
          </h4>
        </div>
      </div>
    </div>
    <div class="home-box">
      <div class="home-box-title">
        <h1>Reviews</h1>
      </div>
    </div>
    <div class="testimonies" ref="swiperContainer" :class="openBox?'visible':''">
      <div class="layer cp" :class="openBox?'bigOpen':''" @click="bigBox('close')"></div>
      <swiper :options="swiperOption3" @slideChange="handleSlideChange" ref="boxSwiper">
        <swiper-slide v-for="(item, index) in testList" :key="index" :class="openBox && (activeIndex === index)?'bigOpen':''">
          <div v-if="openBox && (activeIndex === index)?'bigOpen':''" class="layer sp" :class="openBox?'bigOpen':''" @click="bigBox('close')"></div>
          <div class="testBox" @click="bigBox(index)">
            <div class="_header">{{ item.title }}</div>
            <div class="_body" v-html="item.html"></div>
          </div>

        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import {getIndexbanner,getPlanList, getCurrentRate} from "@/request/api"
import Login from "../../components/User/Login.vue";
import fa from "element-ui/src/locale/lang/fa";
export default {
  name: "home",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      // 头部轮播列表
      imgList: [

      ],
      // 介绍列表
      introList: [
        {
          type: 0,
          imgUrl: require('@/assets/image/icon/icon-vector.png'),
          title: 'Service covers 110 countries around the world',
          span: 'Wide range of eSIM coverage'
        },
        {
          type: 1,
          imgUrl: require('@/assets/image/icon/icon-settings.png'),
          title: 'More than a decade of professional service',
          span: 'Professional customer service'
        },
        {
          type: 0,
          imgUrl: require('@/assets/image/icon/icon-strength.png'),
          title: 'Cooperation with first-tier telecom operators',
          span: 'Ensure a good network with high-quality'
        },
        {
          type: 0,
          imgUrl: require('@/assets/image/icon/icon-charge.png'),
          title: 'Charge by your needs',
          span: 'Multiple data plans'
        },
        {
          type: 1,
          imgUrl: require('@/assets/image/icon/icon-keep.png'),
          title: 'Keep your physical SIM',
          span: 'Reduce the process of changing sim cards '
        },
        {
          type: 0,
          imgUrl: require('@/assets/image/icon/icon-easy.png'),
          title: 'Easy installation',
          span: 'Takes only 3 steps to apply your plan'
        }
      ],
      // 介绍列表
      testList: [
        {
          type: 0,
          title: 'Wang',
          html: '<p>I bought a Japan 2GB/day for 5 Days from eSImart, it lets me connect instantly upon arrival, no need for a physical SIM.instant connection, no physical SIM.\n' +
              'Thanks to eSImart, Kyoto was an unforgettable adventure!</p>'
        },
        {
          type: 1,
          title: 'Enid Smith',
          html: '<p>I want to express my gratitude for your outstanding service. I encountered some issues during the installation of  eSIM after placing an order, prompting me to seek assistance from eSImart. The response from the customer service team was not only prompt and courteous but also displayed an excellent level of professionalism.</p><p>Through our conversation, I learned that the reason for the installation failure was my phone not supporting eSIM functionality. I must admit that this was due to my oversight in not thoroughly checking my phone specifications before placing the order. Despite my mistake, the customer service didn\'t blame me; instead, they patiently guided me to a solution.</p><p>Although the issue was caused by my oversight, I am truly grateful for the patience and assistance provided by the customer service. eSImart demonstrated an exceptional service attitude in addressing the problem, making me feel extremely reassured. Thank you for your assistance, and I look forward to continuing to use your high-quality service in the future.</p>'
        },
        {
          type: 1,
          title: 'Johnson. C',
          html: '<p>Last-minute business trip to Singapore, eSImart\'s small data plan was a lifesaver—no waiting for lengthy delivery.</p><p>Easily purchased their short-term data plan on the website, a quick and affordable process. Activating the eSIM was straightforward, no extra setup needed. Affordable pricing and simple operation kept me conveniently connected during my brief stay. Highly satisfied and considering continued use in future travels.</p><p>Thanks to eSImart\'s Singapore short-term data plan, my trip went smoothly!</p>'
        },
        {
          type: 1,
          title: 'Dylan Mc',
          html: '<p>As a frequent business traveler to Japan, the eSIM solution has been a game-changer for me. Acquiring and activating the eSIM card is straightforward, and its stable connection, whether in Tokyo\'s busy districts or remote areas, is invaluable. This eSIM has become my go-to for seamless communication during work trips. Highly recommended for wohm any needing reliable connectivity in Japan!</p>'
        },
        {
          type: 1,
          title: 'David Smith',
          html: '<p>Planning my trip to Canada was made easy with the quick and affordable purchase of a 3-day, total 5GB eSIM plan on eSImart. Activation upon arrival was a breeze, allowed me to freely explore, share, and navigate without daily limits. The plan provided reliable connectivity in both urban and natural settings, crucial for staying in touch and sharing experiences. I appreciated the flexibility to manage my usage effortlessly, it\'s convenient to purchase extra data online at any time.</p><p>For short stays in Canada, this unrestricted 5GB plan is an excellent choice, offering ample data and a carefree travel experience. Thanks to Canada for providing such a practical eSIM solution, enhancing my Canadian journey!</p>'
        },
        {
          type: 1,
          title: 'Rachel Mitchum',
          html: '<p>I hope this note conveys my gratitude for your outstanding service. Upon arriving in Thailand, I encountered issues with my eSIM. Swiftly reaching out to your customer service, I was highly satisfied with the entire experience. The team responded promptly, patiently explaining that the root cause was my failure to enable roaming on my phone. Admittedly my oversight, but the support provided a solution without a hint of displeasure. I\'d like to take this opportunity to advocate for fellow eSIM users: enabling phone roaming is crucial. This simple step ensures seamless connectivity abroad, avoiding unnecessary hassles. Thanks again for your assistance; I\'m thoroughly impressed with your service. Can\'t wait for the next travel!</p>'
        },
      ],
      // 轮播配置
      swiperOption: {
        // 是否循环
        loop: true,
        // 滑块间隔
        // spaceBetween: 18,
        // 自动播放
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false
        // },
        // 滑动速度
        speed: 800,
        // 滑动方向
        direction: "horizontal",
        // 手掌抓去滑动
        grabCursor: true,
        // 一屏显示子滑块 自动
        slidesPerView: "auto",

        // 显示分页
        // pagination: {
        //   el: ".swiper-pagination", // 分页class
        //   clickable: true //允许分页点击跳转
        // },
        // 设置点击箭头
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev"
        // }
      },
      // 简介轮播配置
      swiperOption2: {
        // 是否循环
        loop: false,
        // 滑块间隔
        spaceBetween: 8,
        // 滑动速度
        speed: 800,
        // 滑动方向
        direction: "horizontal",
        // 手掌抓去滑动
        grabCursor: true,
        // 一屏显示子滑块 自动
        slidesPerView: "auto",
      },
      // User Testimonies轮播配置
      swiperOption3: {
        // 是否循环
        loop: true,
        // centeredSlides: true,
        // 滑块间隔
        spaceBetween: 60,
        // 滑动速度
        speed: 800,
        // 滑动方向
        direction: "horizontal",
        // 手掌抓去滑动
        grabCursor: true,
        // 一屏显示子滑块 自动
        slidesPerView: "auto",
      },
      // 产品列表
      dataList: [

      ],
      rate:0,// 匯率
      activeIndex:0,
      openBox:false
    };
  },
  created(){
      this.getBanner();
      this.getProuductList();
      this.getCurrentRate()
      if(this.$route.query.user_info){
         let userInfo = JSON.parse(window.atob(this.$route.query.user_info))
         let token = JSON.stringify(userInfo.token)
         window.localStorage.setItem('TOKEN_NAME',token)
         window.localStorage.setItem('userInfo',JSON.stringify(userInfo))
          this.$nextTick(() =>{
        this.$store.commit("changeStatus", true);
        this.$store.commit('changgetCart')
        this.$store.commit("changeuserInfo", JSON.stringify(userInfo));
       })
      }
  },
  methods: {
    // 跳转至产品详情
    toDetails(item) {
      this.$router.push({
        path: '/details',
        query: {
          id: item.id
        }
      })
    },
    // 加入购物车
    toShop(item) {
      this.$router.push({
        path: '/details',
        query: {
          id: item.id
        }
      })
    },
    // 加入我喜欢
    toLove(item) {

    },

    //獲取首頁banner
   async getBanner(){
       let res = await getIndexbanner();
       this.imgList = res.data;
    },

    //獲取產品列表
    async getProuductList(){
      let data = {
        type_id:'',
        country_id:'',
        txt:''
      }
       let res = await getPlanList(data);
       this.dataList = res.data.plan;
    },
    async getCurrentRate() {
      const res = await getCurrentRate({})
      this.rate = Number(res.data.rate)
    },
    handleSlideChange(){
      this.activeIndex = this.$refs.boxSwiper.swiper.realIndex;
      this.openBox = false
    },
    bigBox(i){
      if(!this.openBox){
        if(i === this.activeIndex){
          this.openBox = true
          document.body.style.overflow = 'hidden';
        }
      }

      if(i === 'close'){
        this.openBox= false
        document.body.style.overflow = '';
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  padding-bottom: 90px;
  .home-img {
    .swiper-container {
      margin: 34px 0;
      position: relative;
      width: 100%;
      height: 236px;
      .swiper-slide {
        margin: 0 10px;
        width: 412px;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .home-intro {
    margin: 0 auto;
    padding: 24px 55px 0;
    width: 1064px;
    background: #1D1D1D;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .home-intro-div {
      flex-basis: calc(100% / 3);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
      >div{
        flex-grow: 1;
      }
      img {
        margin-right: 20px;
        width: 60px;
        height: 60px;
      }
      .imgs {
        width: 66px;
        height: 66px;
      }
      p {
        margin-bottom: 10px;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        color: #FFFFFF;
      }
      span {
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #C4C4C4;
      }
    }
  }
  .home-intro-swiper {
    display: none;
  }
  .home-box {
    margin: 40px auto 0;
    width: 1174px;
    .home-box-title {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 46px;
        color: #FFFFFF;
      }
      div {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #FFFFFF;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
    .home-box-list {
      display: flex;
      // align-items: center;
      flex-wrap: wrap;
      .list-div {
        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 45px;
        width: 278px;
        .list-div-img {
          width: 100%;
          height: 186px;
          border-radius: 5px;
          position: relative;
          .img {
            width: 100%;
            height: 100%;
          }
          .shop {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 35px;
            height: 35px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            display: none;
            align-items: center;
            justify-content: center;
            img {
              width: 23px;
              height: 23px;
            }
          }
          .love {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 35px;
            height: 35px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            display: none;
            align-items: center;
            justify-content: center;
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
        .list-div-h2 {
          padding: 17px 8px 0;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          height: 20px;
          color: #00A2FD;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .list-div-h3 {
          padding: 2px 8px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #FFFFFF;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .list-div-h4 {
          padding: 0 8px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #FFC700;
          span {
            font-size: 16px;
            line-height: 23px;
          }
          i {
            font-size: 12px;
            line-height: 23px;
            font-style: normal;
            margin-left: 10px;
          }
        }
      }
      .list-div:nth-child(4n+4) {
        margin-right: 0;
      }
      .list-div:hover {
        .list-div-img {
          .shop {
            display: flex;
          }
          .love {
            display: flex;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .home {
    .home-intro {
      width: 890px;
    }
    .home-box {
      width: 1000px;
      .home-box-list {
        .list-div {
          width: calc((100% - 40px) / 3);
        }
        .list-div:nth-child(4n+4) {
          margin-right: 20px;
        }
        .list-div:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .home {
    .home-intro {
      padding: 14px 20px;
      width: 740px;
      .home-intro-div {
        img {
          margin-right: 10px;
          width: 40px;
          height: 40px;
        }
        .imgs {
          width: 44px;
          height: 44px;
        }
        p {
          margin-bottom: 5px;
          font-size: 16px;
          line-height: 20px;
        }
        span {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .home-box {
      width: 780px;
      .home-box-list {
        .list-div {
          width: calc((100% - 40px) / 3);
          .list-div-img {
            width: 100%;
            height: 164px;
          }
        }
        .list-div:nth-child(4n+4) {
          margin-right: 20px;
        }
        .list-div:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .home {
    .home-img {
      .swiper-container {
        margin: 16px 0;
        position: relative;
        width: 100%;
        height: 140px;
        .swiper-slide {
          margin: 0 7.5px;
          width: 246px;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
    .home-intro {
      display: none;
    }
    .home-intro-swiper {
      display: block;
      .swiper-container {
        padding: 0 7.5px;
        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 280px;
          height: 65px;
          background: #1D1D1D;
          border-radius: 10px;
          padding: 5px 10px;
          img {
            margin-right: 9px;
            // width: 38px;
            // height: 38px;
            width: 30px;
            height: 30px;
          }
          div {
            p {
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              color: #FFFFFF;
              font-size: 14px;
              line-height: 18px;
            }
            span {
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              font-size: 10px;
              line-height: 17px;
              color: #C4C4C4;
            }
          }
        }
      }
    }
    .home-box {
      padding: 0 7.5px;
      width: calc(100% - 15px);
      .home-box-title {
        margin-bottom: 12px;
        h1 {
          font-size: 16px;
          line-height: 23px;
        }
        div {
          font-size: 12px;
          line-height: 17px;
          img {
            width: 23px;
            height: 23px;
          }
        }
      }
      .home-box-list {
        .list-div {
          width: calc((100% - 20px) / 2);
          .list-div-img {
            width: 100%;
            height: 105px;
          }
          .list-div-h2 {
            padding: 12px 0 0;
            font-size: 10px;
            line-height: 14px;
          }
          .list-div-h3 {
            padding: 3px 0 0;
            font-size: 14px;
            line-height: 20px;
          }
          .list-div-h4 {
            padding: 0;
            font-size: 10px;
            line-height: 15px;
            span {
              font-size: 14px;
              line-height: 20px;
            }
            i {
              font-size: 10px;
              line-height: 20px;
              margin-left: 6px;
            }
          }
        }
        .list-div:nth-child(3n+3) {
          margin-right: 20px;
        }
        .list-div:nth-child(2n+2) {
          margin-right: 0;
        }
        .list-div:hover {
          .list-div-img {
            .shop {
              display: none;
            }
            .love {
              display: none;
            }
          }
        }
      }
    }
  }
}
.button1 {
  background: #0B57C9;
  cursor: pointer;
  user-select: none;
  width: 199px;
  height: 37px;
  border-radius: 20px;
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:20px auto 0 ;
}
</style>
